<template>
  <GlobalWindow
    :title="title"
    width="500px"
    :visible.sync="visible"
    :confirm-working="isWorking.create"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="数据标签" prop="label" required>
        <el-input v-model="form.label" placeholder="请输入数据标签" v-trim maxlength="50"/>
      </el-form-item>
      <el-form-item label="数据值" prop="code" required>
        <el-tiptap
          v-if="[104,105,106,107,108,115,121,132,135].includes(form.dictId)"
          v-model="form.code"
          :extensions="extensions"
        />
        <el-input v-model="form.code" v-else laceholder="请输入数据值" v-trim />
      </el-form-item>
      <el-form-item label="海报图" v-if="[142].includes(form.dictId)">
          <el-upload
            :action="base"
            :limit="1"
            :file-list="fileList"
            list-type="picture-card"
            :on-success="onSuccess"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
      </el-form-item>
      <el-form-item label="状态" prop="disabled" required class="form-item-status">
        <el-switch v-model="form.disabled" :active-value="false" :inactive-value="true"/>
        <span class="status-text">{{form.disabled | disabledText}}</span>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { upload } from '@/api/system/common'
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Image,
  Blockquote,
  TextAlign,
  Indent,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  HorizontalRule
} from 'element-tiptap'
export default {
  name: 'OperaDictDataWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      base: process.env.VUE_APP_API_PREFIX + '/upload/image',
      fileList: [],
      // 表单数据
      form: {
        id: null,
        dictId: null,
        code: '',
        label: '',
        disabled: false
      },
      // 验证规则
      rules: {
        label: [
          { required: true, message: '请输入数据标签' }
        ],
        code: [
          { required: true, message: '请输入数据值' }
        ]
      },
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true, menubar: false }),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Image({
          uploadRequest (file) {
            const fd = new FormData()
            fd.append('file', file)
            return upload(fd).then(res => {
              return process.env.VUE_APP_API_IMG + res.accessUri
            })
          }
        }),
        new Blockquote(),
        new TextAlign(),
        new Indent(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule(),
        new TextColor()
      ]
    }
  },
  methods: {
    onSuccess(res) {
      if (this.form.code.indexOf('|') === -1) {
        this.form.code = this.form.code + '|' + res.data.accessUri
      } else {
        this.form.code = this.form.code.split('|')[0] + '|' + res.data.accessUri
      }
      this.fileList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
    },
    handleRemove() {
      this.fileList = []
    },
    /**
     * 打开窗口
     *
     * @param title 窗口标题
     * @param dictId 所属字典ID
     * @param target 行对象（仅编辑需该参数）
     */
    open (title, dictId, target) {
      this.title = title
      this.form.dictId = dictId
      this.visible = true
      // 新建
      if (target == null) {
        this.$nextTick(() => {
          this.$refs.form.resetFields()
          this.form.id = null
        })
        return
      }
      // 编辑
      this.$nextTick(() => {
        for (const key in this.form) {
          this.form[key] = target[key]
        }
        if (this.form.dictId === 142 && this.form.code.indexOf('|') !== -1) {
          this.fileList = [{ url: process.env.VUE_APP_API_IMG + this.form.code.split('|')[1] }]
          console.log(this.fileList)
        } else {
          this.fileList = []
        }
      })
    }
  },
  created () {
    this.config({
      api: '/system/dictData'
    })
  }
}
</script>

<style scoped lang="scss">
.form-item-status {
  .el-form-item__content > * {
    width: auto !important;
  }
  .status-text {
    color: #999;
    margin-left: 6px;
    font-size: 13px;
    vertical-align: middle;
  }
}
</style>

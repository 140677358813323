<template>
    <TableLayout :permissions="['renthouse:rhhouse:query']">
        <template v-slot:table-wrap>
           <div class="box">
                <div class="box_left">
                    <div :class="chatId === item.chatId ? 'box_left_item active' : 'box_left_item'" v-for="(item, i) in list" :key="i" @click="connectWebSocket(item.chatId, item.startUserName, item.houseId)">
                        <div class="box_left_item_avar">
                            <img :src="base + item.startUserAvatar" alt="" />
                        </div>
                        <div class="box_left_item_info">
                            <span>{{item.startUserName}}</span>
                            <span>{{item.startUserPhone || '暂未授权手机号'}}</span>
                            <span>{{item.content}}</span>
                        </div>
                        <div class="box_left_num" v-if="item.unRedNum > 0">{{item.unRedNum > 99 ? '99+' : item.unRedNum}}</div>
                    </div>
                </div>
                <div class="box_right" v-if="chatId">
                    <div class="box_right_head">
                        {{title}}
                        <el-button type="primary" @click="getHouse">查看房源</el-button>
                    </div>
                    <div class="box_right_list" id="chat">
                        <div class="box_right_list_row" v-for="(item, index) in messageList" :key="index">
                            <div class="l" v-if="item.position === 'l'">
                                <div class="l_img">
                                    <img :src="userInfo.id === item.startUserId ? base + item.targetUserAvatar : base + item.startUserAvatar" alt="" />
                                </div>
                                <div class="l_message">
                                    <template v-if="item.relatedType == 6">
                                        预约信息
                                    </template>
                                    <template v-if="item.relatedType == 2">
                                        合同信息
                                    </template>
                                    <template v-else>
                                        {{item.content}}
                                    </template>
                                    <div class="l_message_time">{{item.createTime}}</div>
                                </div>
                            </div>
                            <div class="r" v-else>
                                <div class="r_message">
                                    {{item.content}}
                                    <div class="r_message_time">{{item.createTime}}</div>
                                </div>
                                <div class="r_img">
                                    <img :src="userInfo.id === item.startUserId ? base + item.startUserAvatar : base + item.targetUserAvatar" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box_right_footer">
<!--                        <el-dropdown @command="send">-->
<!--                          <span class="el-dropdown-link">-->
<!--                            常用语<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                          </span>-->
<!--                          <el-dropdown-menu slot="dropdown">-->
<!--                            <el-dropdown-item v-for="(item, index) in docList" :key="index" :command="item.code">{{item.code}}</el-dropdown-item>-->
<!--                          </el-dropdown-menu>-->
<!--                        </el-dropdown>-->
                        <el-input v-model="input" style="width: 400px;" placeholder="请输入内容"></el-input>
                        <el-button type="primary" style="margin-left: 10px;" :loading="loading" :disabled="loading" @click="send('')">发送</el-button>
                    </div>
                </div>
                <div class="box_right" v-else>
                    <div class="box_right_not">
                        <img src="@/assets/images/chat.png" alt="" />
                    </div>
                </div>
           </div>
        </template>
        <OperaRhHouseWindow ref="operaRhHouseWindow" />
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import OperaRhHouseWindow from '@/components/rentHouse/OperaRhHouseWindow'
  import { fetchList, send, page, readMsg, flushChatWeb } from '@/api/rentHouse/rhChat'
  import { findByDictCode } from '@/api/system/common'
  import { fetchList as house } from '@/api/rentHouse/rhHouse'

  export default {
    name: 'chatRoom',
    extends: BaseTable,
    components: { TableLayout, OperaRhHouseWindow },
    data () {
      return {
        list: [],
        chatId: null,
        online: null,
        title: '',
        input: '',
        ws: '',
        options: [],
        messageList: [],
        base: process.env.VUE_APP_API_IMG,
        timer: null,
        docList: [],
        loading: false,
        houseId: null
      }
    },
    created () {
      this.getList()
      this.onlines()
      // this.getDoc()
      this.timer = setInterval(() => {
        this.getList()
      }, 2000)
    },
    beforeDestroy() {
      if (this.chatId) {
        flushChatWeb(this.chatId, 1)
      }
      if (this.ws) {
        console.log('已退出房间')
        this.ws.close();
      }
      if (this.online) {
        console.log('已下线')
        this.online.close();
      }
      clearInterval(this.timer)
    },
    methods: {
      getHouse() {
        house({
          capacity: 10,
          page: 1,
          model: {
            id: this.houseId
          }
        }).then(res => {
          this.$refs.operaRhHouseWindow.open('房源详情', {...res.records[0], seeType: 'desc'})
        })
      },
      getDoc() {
        findByDictCode({
          dictCode: 'sys_house_commonphrases'
        }).then(res => {
          this.docList = res
        })
      },
      // 发送消息
      send(message) {
        if (!message && !this.input) return this.$message.warning('请先输入内容！')
        this.loading = true
        send({ chatId: this.chatId, content: message ? message : this.input, type: 1 })
          .then(res => {
            this.input = '';
          })
          .finally(() => {
            this.loading = false
          })
      },
      cloarMsg(chatId) {
        readMsg(chatId)
      },
      // 进入聊天室
      connectWebSocket(chatId, name, houseId) {
        if (this.chatId) {
          flushChatWeb(this.chatId, 1)
        }
        this.houseId = houseId
        flushChatWeb(chatId, 0)
        this.messageList = []
        this.chatId = chatId
        this.title = name
        this.getInfo()
        this.cloarMsg(chatId)
        if (this.ws) {
          console.log(`已退出聊天室`);
          this.ws.close();
        }
        this.ws = new WebSocket(`${process.env.VUE_APP_API_SCOKET}websocket/message/${chatId}/${this.userInfo.id}`);
        this.ws.onopen = () => console.log(`已进入${chatId}聊天室`);
        this.ws.onerror = (error) => console.log('进入聊天室出错:', error);
        this.ws.onmessage = (message) => {
          const parsedMessage = JSON.parse(message.data);
          parsedMessage.createTime = this.timestampToDateTime(parsedMessage.createTime)
          parsedMessage.position = this.userInfo.id === parsedMessage.startUserId ? 'r' : 'l'
          this.messageList.push(parsedMessage)
          this.scrollToBottom()
        };
      },
      scrollToBottom(){
        this.$nextTick(()=>{
          let chat = this.$el.querySelector("#chat")
          chat.scrollTop = chat.scrollHeight
        })
      },
      // 获取聊天记录
      getInfo() {
        page({ capacity: 99999, page: 1, model: { chatId: this.chatId } })
            .then(res => {
              console.log('成功获取消息记录')
              if (res.records && res.records.length > 0) {
                res.records.forEach(item => {
                  item.position = this.userInfo.id === item.startUserId ? 'r' : 'l'
                })
                this.messageList.push(...res.records)
                this.scrollToBottom()
              }
            })
      },
      timestampToDateTime(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      // 在线
      onlines() {
        this.online = new WebSocket(`${process.env.VUE_APP_API_SCOKET}websocket/online/${this.userInfo.id}`);
        this.online.onopen = () => console.log('WebSocket 连接成功 已上线');
        this.online.onerror = (error) => console.log('WebSocket 连接出错:', error);
      },
      // 获取聊天列表
      getList () {
        fetchList({})
          .then(res => {
            this.list = res
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .box {
        width: 100%;
        height: 70vh;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .box_left {
            flex-shrink: 0;
            width: 220px;
            height: 100%;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 3px;
                background-color: #f1f1f1;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 5px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background-color: #999;
            }
            .active {
                background: #ececec !important;
            }
            .box_left_item {
                width: 100%;
                height: 60px;
                display: flex;
                align-items: center;
                background: #ffffff;
                padding: 0 10px;
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                &:hover {
                    background: #ececec;
                }
                .box_left_num {
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    background: red;
                    color: #fff;
                    font-size: 12px;
                }
                .box_left_item_avar {
                    width: 50px;
                    height: 50px;
                    overflow: hidden;
                    flex-shrink: 0;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    overflow: hidden;
                    img {
                        width: 100%;
                    }
                }
                .box_left_item_info {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    span {
                        &:nth-child(1) {
                            font-size: 12px;
                            color: #282828;
                        }
                      &:nth-child(2) {
                        font-size: 12px;
                        color: #282828;
                      }
                      &:nth-child(3) {
                          font-size: 12px;
                          color: #8e8e8e;
                      }
                    }
                }
            }
        }
        .box_right {
            flex: 1;
            height: 100%;
            background: #ffffff;
            .box_right_not {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 70px;
                    height: 70px;
                }
            }
            .box_right_head {
                width: 100%;
                height: 50px;
                line-height: 50px;
                padding: 0 15px;
                box-sizing: border-box;
                border-bottom: 1px solid #ececec;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .box_right_list {
                width: 100%;
                height: calc(100% - 100px);
                padding: 10px 10px 0 10px;
                box-sizing: border-box;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 3px;
                    background-color: #f1f1f1;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #888;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background-color: #999;
                }
                .box_right_list_row {
                    margin-bottom: 30px;
                    .l {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-content: center;
                        .l_img {
                            flex-shrink: 0;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            margin-right: 10px;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                        .l_message {
                            padding: 8px 8px;
                            font-size: 12px;
                            color: #fff;
                            background: #282828;
                            border-radius: 10px;
                            position: relative;
                            .l_message_time {
                                white-space: nowrap;
                                position: absolute;
                                bottom: -20px;
                                left: 0;
                                font-size: 12px;
                                color: #999;
                            }
                        }
                    }
                    .r {
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: flex-end;
                        .r_img {
                            flex-shrink: 0;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            margin-left: 10px;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                        }
                        .r_message {
                            padding: 8px 8px;
                            font-size: 12px;
                            color: #fff;
                            background: #282828;
                            border-radius: 10px;
                            position: relative;
                            .r_message_time {
                                white-space: nowrap;
                                position: absolute;
                                bottom: -20px;
                                right: 0;
                                font-size: 12px;
                                color: #999;
                            }
                        }
                    }
                }
            }
            .box_right_footer {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                box-sizing: border-box;
                border-top: 1px solid #ececec;
                span {
                    color: #282828;
                    font-size: 13px;
                }
                .el-dropdown-link {
                    cursor: pointer;
                }
            }
        }
    }
</style>
